<template>
  <div>
    <Header />
    <div class="page-content">
      <section class="services top-padding-zero" data-aos="zoom-in">
        <div class="container">
          <h2 class="sectio-title">{{ $t("about.about-title") }}</h2>
          <div class="desc">
            {{ $t("about.about-desc") }}
          </div>
          <div class="desc">
            {{ $t("about.about-desc-1") }}
          </div>
        </div>
      </section>
      <section class="services" id="hero" data-aos="zoom-in">
        <div class="container">
          <h2 class="sectio-title">{{ $t("about.about-vision") }}</h2>
          <div class="desc">
            {{ $t("about.vision-desc") }}
          </div>
        </div>
      </section>
      <section class="services" data-aos="zoom-in">
        <div class="container">
          <h2 class="sectio-title">{{ $t("about.about-mission") }}</h2>
          <div class="desc bottom-60">
            {{ $t("about.mission-desc") }}
          </div>
          <div class="cards">
            <div class="card card-img">
              <div class="img-outer">
                <img src="assets/img/financial-freedom.svg" />
              </div>
              <h3 class="card-title">{{ $t("about.mission-title-1") }}</h3>
              <p class="card-text">
                {{ $t("about.mission-desc-1") }}
              </p>
            </div>
            <div class="card card-img">
              <div class="img-outer">
                <img src="assets/img/security-trust.svg" />
              </div>
              <h3 class="card-title">{{ $t("about.mission-title-2") }}</h3>
              <p class="card-text">
                {{ $t("about.mission-desc-2") }}
              </p>
            </div>
            <div class="card card-img">
              <div class="img-outer">
                <img src="assets/img/innovation.svg" />
              </div>
              <h3 class="card-title">{{ $t("about.mission-title-3") }}</h3>
              <p class="card-text">
                {{ $t("about.mission-desc-3") }}
              </p>
            </div>
            <div class="card card-img">
              <div class="img-outer">
                <img src="assets/img/global-community.svg" />
              </div>
              <h3 class="card-title">{{ $t("about.mission-title-4") }}</h3>
              <p class="card-text">
                {{ $t("about.mission-desc-4") }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BNavbarNav,
  BNavbarToggle,
  BNavbar,
  BCollapse,
  BRow,
  BCol,
  BCard,
  BCardText
} from "bootstrap-vue";
import store from "@/store/index";
import Locale from "@/layouts/components/Locale.vue";
import Contact from "@/assets/images/pages/Contact.svg";
import Logo from "@/assets/rashiCoin/logo.svg";
import Header from "./../Header.vue";
import Footer from "./../Footer.vue";
export default {
  components: {
    BButton,
    BImg,
    BNavbarNav,
    BNavbarToggle,
    BNavbar,
    BCollapse,
    Locale,
    BRow,
    BCol,
    BCard,
    BCardText,
    Contact,
    Logo,
    Header,
    Footer
  },
  data() {
    return {
      isActive: 1,
      lang: this.$route.params.lang
    };
  },
  mounted() {},
  methods: {
    changeTab(tab) {
      this.isActive = tab;

      if (tab === 2) {
        document.querySelector(".tab-slider--tabs").classList.add("slide");
      } else {
        document.querySelector(".tab-slider--tabs").classList.remove("slide");
      }
    },
    openPage(props) {
      this.$router.push({
        name: props,
        params: { lang: this.lang || undefined }
      });
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        return this.downImg;
      }
      return this.downImg;
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/landing/css/style.scss";
@import "@core/scss/vue/pages/page-misc.scss";
</style>
<style lang="scss" scoped>
.page-content {
  min-height: 90vh;
  padding-top: 0px;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px 90px;
}
.services .card .img-outer {
  background: #e8f5e9;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 65%;
  }
}
.services .card h3.without-img {
  margin-top: 0px;
}
</style>
